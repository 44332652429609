import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import ArticleList from "../components/ArticleList"
// import Pagination from '../components/Pagination'



const CategoryTemplate = ( props ) => {
    console.log(props)
    const { pageContext, data } = props;
  const { category } = pageContext
  const { allMdx } = data
  const posts = allMdx.edges.map(item => item.node) || null


    return (
        <div>
        <SEO 
          title={`Category ${category}`}
          description={`Posts in the category ${category} from my little blog about tech, side projects, entrenpreneurship & things.`}
          article={false}
       />
        <div className="blogIndex">
      {posts ? (
        <div className="container">
          
          <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-between alignt-items-center">
            <h1 className="giant primary-color">Category <span style={{color: "#fff"}}>{category}</span></h1>
            </div>
            <div className="col-12">
              <ArticleList posts={posts} />
            </div>
          </div>
        </div>
      ) : null}
      </div>


    </div>
    )
}

//      filter: { fields: { category: { eq: $category } } } ($category: String)

export const pageQuery = graphql`
  query CategoryPage($category: String) {
    site {
        siteMetadata{
            title
        }
    }
    allMdx(
      limit: 1000
      filter: {frontmatter: { category: { eq: $category}}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            category
            image {
              ...ImageFields
            }
          }
        }
      }
    }
  }
`

export default CategoryTemplate